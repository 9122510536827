import { Ref } from '@vue/runtime-dom'

export interface IToggleHook {
	toggleValue: Ref<boolean>
	toggle: () => void
	setToggle: (newToggleValue: boolean) => void
}

export function useToggle(initialValue: boolean = false): IToggleHook {
	const toggleValue = ref<boolean>(initialValue)

	function toggle() {
		toggleValue.value = !toggleValue.value
	}

	function setToggle(newToggleValue: boolean) {
		toggleValue.value = newToggleValue
	}

	return {
		toggleValue,
		toggle,
		setToggle,
	}
}
